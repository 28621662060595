/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import "./MyStatus.scss";
import MPHStatusComponent from "src/components/actionPerformers/MPHStatusComponent/MPHStatusComponent";

const MyStatus = () => {
  return (
    <>
      <MPHHeaderMain active="/status" />
      <div className="margin-top">
        <div className="my-status">
          <MPHStatusComponent />
        </div>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyStatus;
