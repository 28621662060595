/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import MPHButton from "src/components/actionPerformers/MPHButtons/MPHButton";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHTextarea from "src/components/DataProviders/MPHTextArea";
import { errorToast } from "src/utils/toast";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import {
  contactValidation,
  contactSchema,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useStorage } from "src/utils/storage";
import MPHDropdown from "src/components/DataProviders/MPHDropdown";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useBlockUIContext } from "ns-react-block-ui";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { CONTACT_EMAIL } from "src/config/constants";
import "./Contact.scss";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setBlockUI } = useBlockUIContext();
  const [initialValues] = useState(contactSchema);
  const [base64String, setBase64String] = useState({
    fileData: "",
    fileName: "",
    fileType: "",
  });
  const storage = useStorage();
  const [email, setEmail] = useState("");
  const fileInputRef: any = useRef(null);
  const [hotelsList, setHotelsList] = useState([]);
  const navigate = useNavigate();
  const { contactUs, getHotelsList, getCustomerByEmailLoggedIn } =
    useApiAxiosRequests();
  const location = useLocation();

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const reader: any = new FileReader();

    reader.onload = () => {
      const base64 = reader.result.split(",")[1]; // Get the base64 string after the comma
      setBase64String({
        fileData: base64,
        fileName: file.name,
        fileType: file.type,
      });
    };

    // Read the file as a data URL which will trigger the onload event above
    reader.readAsDataURL(file);
  };

  const handleSaveNext = async () => {
    if (Object.keys(errors).length) {
      Object.keys(initialValues).forEach((fieldName) => {
        setFieldTouched(fieldName, true); // Set each field as touched
      });
      return;
    }
    const payload = {
      root: {
        email: [
          {
            ...values,
            body: {
              firstname: values.firstname,
              mobile: values.mobile,
              membership: values.membership,
              checkIn: values.checkIn,
              checkOut: values.checkOut,
              confirmationNumber: values.confirmationNumber,
              destination: values.destination,
              body: values.body,
            },
            attachments: {
              attachment: [
                {
                  file_name: base64String.fileName.replace(/\s/g, ""),
                  file_type: base64String.fileType,
                  file_data: base64String.fileData,
                  file_encoding_type: "base64",
                },
              ],
            },
          },
        ],
      },
    };
    setIsLoading(true);
    const res: any = await contactUs(payload, email);
    setIsLoading(false);
    if (res && res.data && res.data.status && !res.data.status.success) {
      return;
    }
    if (res && res.data && res.data.status && res.data.status.success) {
      setFieldValue("subject", "");
      setFieldValue("checkIn", "");
      setFieldValue("checkOut", "");
      setFieldValue("confirmationNumber", "");
      setFieldValue("destination", "");
      setFieldValue("body", "");
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input field
      }
      Swal.fire({
        title: "Success",
        text: "Your request has been submitted to the Stay Rewarded team for review. Please expect a response in 3-4 business days.",
        icon: "success",
        customClass: {
          confirmButton: "secondary-button",
        },
      }).then((res) => {
        navigate("/status");
        window.scrollTo(0, 0);
      });
    } else {
      errorToast(
        (res && res.data && res.data.message) ||
          "Unable to send request, please validate details"
      );
    }
  };

  const getHotelsListFunc = async () => {
    const res: any = await getHotelsList();
    if (
      res &&
      res.data &&
      res.data.response &&
      res.data.response.status.success
    ) {
      let hotels = res.data.response.organization.entities.entity?.filter(
        (val: any) => val.external_id_2 === "MPH"
      );
      setHotelsList(handleHotelsListSuccess(hotels));
    }
    return res;
  };

  const handleHotelsListSuccess = (hotelList: any) => {
    return hotelList
      .map((hotel: any) => {
        return {
          value: hotel.code,
          label: hotel.name,
          name:
            hotel.name.split("My Place Hotel-")?.[1] ||
            hotel.name.split("My Place Hotel_")?.[1] ||
            hotel.name,
        };
      })
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const getloggedInUserFunc = async (email: any) => {
    setBlockUI({ blocking: true });
    const res: any = await getCustomerByEmailLoggedIn({ email });
    setBlockUI({ blocking: false });
    if (
      res &&
      res.data &&
      res.data.status &&
      res.data.status.success === "true"
    ) {
      const user =
        res.data.customers &&
        res.data.customers.customer &&
        res.data.customers.customer.length &&
        res.data.customers.customer[0];
      if (user) {
        setFieldValue("firstname", user.firstname);
        setFieldValue("mobile", user.mobile);
        setFieldValue("membership", user.external_id);
        setFieldValue("from", user.email);
        setFieldValue("to", CONTACT_EMAIL);
      }
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      setEmail(JSON.parse(store).email);
      getloggedInUserFunc(JSON.parse(store).email);
      getHotelsListFunc();
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: contactValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });
  const {
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    touched,
    isValid,
  } = formik;

  return (
    <>
      <MPHHeaderMain active="/contact" />
      <div className="margin-top mt-web">
        {/* <MPHBanner
          url={banner}
          isFind={false}
          header={"Where will you go next?"}
          helperText={""}
        /> */}
        <div className="container mb-5 mt-3">
          <div className="label-cards mt-5">
            <h2>Contact Us</h2>
            <div className="row mt-4">
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <MPHInput
                  keyboardType="text"
                  placeholder="Enter Name"
                  disabled={true}
                  label={"Name"}
                  value={values.firstname}
                  onBlur={handleBlur("firstname")}
                  onChangeText={(text: any) => {
                    handleChange("firstname")(text);
                  }}
                  isError={touched.firstname! && !!errors.firstname}
                  error={errors.firstname}
                />
              </div>
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <MPHInput
                  keyboardType="number"
                  placeholder="Enter Phone Number"
                  label={"Phone Number"}
                  value={values.mobile}
                  onBlur={handleBlur("mobile")}
                  onChangeText={(text: any) => {
                    handleChange("mobile")(text);
                  }}
                  isError={touched.mobile! && !!errors.mobile}
                  error={errors.mobile}
                />
              </div>
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <MPHInput
                  keyboardType="email"
                  placeholder="Enter Email"
                  label={"Email Address"}
                  disabled={true}
                  value={email}
                />
              </div>
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <MPHInput
                  keyboardType="text"
                  disabled={true}
                  placeholder="Enter Membership Number"
                  label={"Membership Number"}
                  value={values.membership}
                />
              </div>
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <MPHDropdown
                  data={[
                    { label: "Missing Stays/Points", value: "MSP" },
                    { label: "Points Redemption Inquiry", value: "PRI" },
                    { label: "Reservations Inquiry", value: "RI" },
                    { label: "Feedback on Property", value: "FP" },
                    { label: "General Program Questions", value: "GPQ" },
                    { label: "Duplicate Account", value: "DA" },
                    { label: "OnLine Log in Questions", value: "OLQ" },
                  ]}
                  labelField="label"
                  valueField="value"
                  placeholder="Select Subject"
                  label="Subject"
                  value={values.subject}
                  onChangeText={(text: any) => {
                    handleChange("subject")(text);
                  }}
                  isError={touched.subject! && !!errors.subject}
                  error={errors.subject}
                />
              </div>
              {["MSP", "RI", "FP"].includes(values.subject) ? (
                <>
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                    <MPHDropdown
                      data={hotelsList}
                      labelField="label"
                      valueField="value"
                      placeholder="Select Name of Property"
                      label="Name of Property"
                      value={values.destination}
                      onChangeText={(text: any) => {
                        handleChange("destination")(text);
                      }}
                      isError={touched.destination! && !!errors.destination}
                      error={errors.destination}
                    />
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                    <MPHInput
                      keyboardType="text"
                      placeholder="Enter Confirmation Number"
                      label={"Confirmation Number"}
                      value={values.confirmationNumber}
                      onBlur={handleBlur("confirmationNumber")}
                      onChangeText={(text: any) => {
                        handleChange("confirmationNumber")(text);
                      }}
                      isError={
                        touched.confirmationNumber! &&
                        !!errors.confirmationNumber
                      }
                      error={errors.confirmationNumber}
                    />
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-3">
                    <label className="form-label">CHECK IN DATE</label>
                    <DatePicker
                      onChange={(text) => {
                        setFieldValue("checkIn", text?.format("MM-DD-YYYY"));
                      }}
                      className={"form-input"}
                      format="MM-DD-YYYY"
                    />
                    {touched.checkIn! && !!errors.checkIn && (
                      <p className="error-code text-decoration-none">
                        {errors.checkIn}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-3">
                    <label className="form-label">CHECK OUT DATE</label>
                    <DatePicker
                      onChange={(text) => {
                        setFieldValue("checkOut", text?.format("MM-DD-YYYY"));
                      }}
                      className={"form-input"}
                      format="MM-DD-YYYY"
                    />
                    {touched.checkOut! && !!errors.checkOut && (
                      <p className="error-code text-decoration-none">
                        {errors.checkOut}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-12">
                <MPHTextarea
                  label="Message"
                  onBlur={handleBlur("message")}
                  placeholder=""
                  value={values.body}
                  onChangeText={(text: any) => {
                    handleChange("body")(text);
                  }}
                  isError={touched.body! && !!errors.body}
                  error={errors.body}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Attachment</label>
                <input
                  type="file"
                  ref={fileInputRef}
                  className={"form-input"}
                  placeholder="Select Attachment"
                  onChange={handleFileInputChange}
                />
              </div>
              <div className="col-12 mt-4">
                <MPHButton
                  buttonText="Send"
                  disabled={isLoading}
                  buttonType="secondary-button"
                  isLoading={isLoading}
                  onbuttonClick={() => handleSaveNext()}
                />
              </div>

              {/* <div className="col-12 mt-4">
                <h5 className="conatct-note-header">
                  IMPORTANT UPDATE: 01/25/24
                </h5>
                <h5 className="conatct-note">
                  Due to the transition to our new Stay Rewarded® Portal, there
                  will be a short delay in updating your available points. Rest
                  assured, all previously earned points from recent stays are
                  securely logged and will be reflected in your account within
                  the next few days. We appreciate your patience during this
                  transition.
                </h5>
              </div> */}
            </div>
          </div>
        </div>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default Contact;
