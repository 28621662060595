/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import "./HomePage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useStorage } from "src/utils/storage";
import MPHFindHotels from "src/components/actionPerformers/MPHFindHotels/MPHFindHotels";
import MPHLogin from "src/components/actionPerformers/MPHLogin/MPHLogin";
import MPHSignUp from "src/components/actionPerformers/MPHSignUp/MPHSignUp";
import { useMPHSSO } from "src/utils/ApiService/MPHSSO";
import { useBlockUIContext } from "ns-react-block-ui";
import Swal from "sweetalert2";
import useAuth from "src/context/AuthContext";
import ReactGA from "react-ga";

const HomePage = (authType: any) => {
  const { setBlockUI } = useBlockUIContext();
  const { setUserProfile, clearStorage } = useAuth();
  const [isSwal, setIsSwal] = useState(false);
  const navigate = useNavigate();
  const storage = useStorage();
  const location = useLocation();
  const { getCustomerLookUpApi } = useMPHSSO();

  const isSsoNavigation = () => {
    if (location.search.includes("backurl")) {
      return true;
    } else {
      return false;
    }
  };

  const ssoConfirmation = (user: any) => {
    if (Swal.isVisible() || isSwal) {
      return;
    }
    Swal.fire({
      title: "Confirmation",
      text: `You are logged in as ${user.profileName}, Would you like to continue or login in with new profile`,
      icon: "info",
      cancelButtonText: "New Login",
      confirmButtonText: "Continue",
      showCancelButton: true,
      customClass: {
        confirmButton: "secondary-button",
        cancelButton: "secondary-button",
      },
    }).then(
      async (res) => {
        if (res.isConfirmed) {
          setBlockUI({ blocking: true });
          await getCustomerLookUpApi(user);
          setBlockUI({ blocking: false });
          navigate("/find-hotels");
        }
        if (res.isDismissed) {
          clearStorage();
          setUserProfile(null);
        }
      },
      () => {}
    );
  };

  const checkIsLogin = async () => {
    const profile: any = await storage.get("user_profile");
    const user = profile && JSON.parse(profile || {});
    if (user && user.isProfileExist && authType && authType.type) {
      // if (isSsoNavigation()) {
      //   ssoConfirmation(user);
      // } else {
      navigate("/find-hotels");
      // return
      // }
    } else if (authType && authType.type === "find-hotels") {
      navigate("/login");
    } else if (
      (!user || !user.isProfileExist) &&
      (!authType || !authType.type)
    ) {
      navigate("/login");
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    checkIsLogin();
    setIsSwal(true);
  }, [authType]);

  return (
    <>
      <MPHHeaderMain active="" />
      <div className="margin-top">
        <section className="home-background">
          {authType && authType.type === "find-hotels" ? (
            <MPHFindHotels />
          ) : (
            <></>
          )}
          {authType &&
          ["login", "create-password", "forgot-password"].includes(
            authType.type
          ) ? (
            <MPHLogin screenType={authType.type} />
          ) : (
            <></>
          )}
          {authType && authType.type === "signup" ? <MPHSignUp /> : <></>}
        </section>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default HomePage;
