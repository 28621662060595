import React from "react";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import MPHProfileForms from "src/components/actionPerformers/MPHProfileForms/MPHProfileForms";

const MyProfile = () => {
  return (
    <>
      <MPHHeaderMain active="/profile" />
      <div className="margin-top">
        {/* <MPHBanner
          url={banner}
          header={"Where will you go next?"}
          helperText={""}
        /> */}
        <div className="my-profile">
          <MPHProfileForms />
        </div>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyProfile;
