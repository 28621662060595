/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import MPHModalDetails from "src/components/actionPerformers/MPHModalDetails/MPHModalDetails";
import MPHBanner from "src/components/DataHolders/MPHBanners/MPHBanner";
import MPHPrimaryCard from "src/components/DataHolders/MPHPrimaryCard/MPHPrimaryCard";
import { INTERNAL_REDIRECTION_URLS } from "src/config/constants";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import banner from "../../assets/banners/banner.jpg";
import "./EarnPoints.scss";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const EarnPoints = () => {
  const [earnPoints, setEarnPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCrmData } = useApiAxiosRequests();
  const location = useLocation();

  const getEarnPointsFunc = async () => {
    setIsLoading(true);
    const res: any = await getCrmData("earnpoints");
    setIsLoading(false);
    if (res && res.data && res.data.data) {
      setEarnPoints(res.data.data || []);
      return;
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getEarnPointsFunc();
  }, []);

  const navigateToPage = (redirect?: any) => {
    if (redirect) {
      window.open(redirect, "_blank");
    }
  };

  return (
    <>
      <MPHHeaderMain active="/earn" />
      <div className="margin-top mt-web">
        <MPHBanner
          url={banner}
          header={"Where will you go next?"}
          helperText={""}
        />
        <section className="container mobile-device">
          <section className="d-flex justify-between">
            <div className="label-cards mb-3">
              <h2>Earn Points</h2>
            </div>
          </section>
          <div className="row min-200">
            {earnPoints.map((card: any, index: any) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-5">
                <MPHPrimaryCard
                  key={index}
                  url={card.image || banner}
                  header={card.title}
                  height={"small"}
                  helperText={card.description}
                  buttonType={"secondary-button"}
                  buttonText={"Learn More"}
                  onbuttonClick={() => {
                    navigateToPage(
                      card.url || INTERNAL_REDIRECTION_URLS.MPH_EARN_POINTS
                    );
                  }}
                />
              </div>
            ))}{" "}
          </div>
        </section>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default EarnPoints;
